<template>
  <div>
    <panel-resizable
      v-if="sbsSidebarToggle"
      side="left"
      class="ant-glass-background full-height ant-border-right"
      style="border-radius: 0"
      :min-width="200"
      :default-width="defautWidht"
      @collapse="closeSbsSidebar"
      @resize-end="onStopResize"
    >
      <div class="d-flex flex-column flex-grow-1 full-height overflow-x-auto">
        <div class="d-flex align-center">
          <v-text-field
            v-model="sbsSearch"
            class="flex-grow-1"
            flat
            filled
            dense
            clearable
            hide-details
            :loading="treeLoading"
            prepend-inner-icon="mdi-magnify"
            placeholder="Type here to search"
            @click:clear="clearSbsSearch"
            @keydown.enter="searchSbsTree"
          >
            <template #append>
              <v-menu
                v-if="sortedVersions.length > 0"
                transition="slide-y-transition"
                bottom
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    icon
                    width="24"
                    height="24"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon> mdi-history</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, i) in sortedVersions"
                    :key="i"
                    dense
                    @click="selectSbsVersion(item)"
                  >
                    <v-list-item-icon color="primary">
                      <v-icon :color="isVersionSelected(item) ? 'primary' : ''">
                        {{
                          isVersionSelected(item)
                            ? 'mdi-radiobox-marked'
                            : 'mdi-radiobox-blank'
                        }}
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ item.label }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-text-field>
        </div>
        <div class="d-flex align-center justify-space-between py-1 mx-2">
          <div class="text-subtitle-2 py-1">
            {{ $t('system.sbsTree.sbsObj') }}
          </div>
          <div v-if="selectedSbsVersion">
            <span class="text-caption ml-2"
              >{{ $t('general.version') | capitalize }}:</span
            >
            <v-chip
              close
              small
              class="ml-2"
              color="primary"
              @click:close="deselectSbsVersion"
            >
              {{ selectedSbsVersion.label }}
            </v-chip>
          </div>
        </div>
        <div class="d-flex flex-column flex-grow-1 overflow-y-auto">
          <v-treeview
            v-if="sbsTreeStatus === 'success'"
            class="overflow-y-auto flex-1 mb-2"
            :items="getTreeItems"
            :load-children="fetchSbsChildren"
            :open.sync="open"
            item-key="code"
            :active="[sbsSearch, activeSbsCode]"
            :open-all="sbsSearch ? sbsSearch.length > 2 : false"
            dense
            hoverable
            multiple-active
          >
            <template #prepend="{ item, open }">
              <v-icon v-if="item.workflow" small color="primary lighten-4">
                mdi-source-branch
              </v-icon>
              <v-icon
                v-else-if="!item.workflow && item.hasChildren"
                color="primary"
                small
              >
                {{ open ? 'mdi-cards-diamond-outline' : 'mdi-cards-diamond' }}
              </v-icon>
              <v-icon v-else x-small color="primary lighten-2">
                mdi-checkbox-blank-circle
              </v-icon>
            </template>
            <template #label="{ item }">
              <div
                v-if="item.label"
                class="d-flex align-center"
                :style="[!isSelectedObject(item) && { cursor: 'pointer' }]"
                @click="selectSbsObject(item)"
              >
                {{ item.label }}
                <v-divider vertical class="mx-2" />
                <span style="font-size: 11px; color: grey">
                  {{ item.code }}
                </span>
              </div>
              <div
                v-else
                :style="[!isSelectedObject(item) && { cursor: 'pointer' }]"
                @click="selectSbsObject(item)"
              >
                {{ item.code }}
              </div>
            </template>
            <template #append="{ item }">
              <v-icon
                v-if="isSelectedObject(item)"
                class="ant-icon mr-2"
                small
                @click="deselectObjectTree"
              >
                mdi-close
              </v-icon>
            </template>
          </v-treeview>
          <div v-else class="d-flex flex-grow-1 justify-center align-center">
            <ant-loading />
          </div>
          <div
            :style="{ height: activeSbsCode ? '400px' : '0' }"
            class="sbs-detail-container"
          >
            <div
              v-if="activeSbsCode"
              class="full-height full-width py-2 ant-border-top overflow-y-auto"
            >
              <template v-if="selectedSbsObject?.code === activeSbsCode">
                <v-expansion-panels
                  v-model="detailExpansions"
                  accordion
                  flat
                  multiple
                >
                  <v-expansion-panel v-if="project.generalPermissions.tasks">
                    <v-expansion-panel-header>
                      <div class="fs-12 flex-0-important">
                        {{ selectedSbsObject?.rfis?.length }}
                      </div>
                      <v-icon small class="mx-2 flex-0-important"
                        >mdi-message-text-outline
                      </v-icon>
                      RFI's
                      <v-spacer />
                      <v-icon
                        v-if="
                          detailExpansions.findIndex((item) => item === 0) !==
                          -1
                        "
                        class="flex-0-important ant-icon mr-2"
                        @click.stop="showRfiCreate = true"
                        >mdi-plus
                      </v-icon>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <task-v2
                        v-for="rfi in selectedSbsObject?.rfis"
                        :key="rfi.id"
                        :task="rfi"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                    v-if="project.generalPermissions.workflows"
                  >
                    <v-expansion-panel-header>
                      <div class="fs-12 flex-0-important">
                        {{ selectedSbsObject?.sessions?.length }}
                      </div>
                      <v-icon small class="mx-2 flex-0-important"
                        >mdi-source-branch
                      </v-icon>
                      {{ $t('system.sessions.multi') | capitalize }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div
                        v-if="selectedSbsObject?.sessions?.length > 0"
                        class="d-flex flex-column"
                      >
                        <div
                          v-for="session in selectedSbsObject?.sessions"
                          :key="session.id"
                          class="ant-glass-background d-flex align-center pa-2 sbs-detail-session-container"
                          @click="openWorkflowSession(session)"
                        >
                          <v-progress-circular
                            :rotate="360"
                            :width="4"
                            size="40"
                            :value="getSessionProgress(session)"
                            color="primary"
                            class="mr-2"
                          >
                            <div class="fs-10">
                              {{ getSessionProgress(session) }}%
                            </div>
                          </v-progress-circular>
                          <div class="d-flex flex-column">
                            <div class="d-flex">
                              <div>
                                {{ session.name }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-else class="font-italic">
                        {{ $t('system.sbsTree.noSessions') }}
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel v-if="project.generalPermissions.tasks">
                    <v-expansion-panel-header>
                      <div class="fs-12 flex-0-important">
                        {{ selectedSbsObject?.tasks?.length }}
                      </div>
                      <v-icon small class="mx-2 flex-0-important"
                        >mdi-clipboard-text-multiple-outline
                      </v-icon>
                      {{ $t('system.tasks.multi') | capitalize }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <task-v2
                        v-for="task in selectedSbsObject?.tasks"
                        :key="task.id"
                        :task="task"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel v-if="isForgeActive">
                    <v-expansion-panel-header>
                      <div class="fs-12 flex-0-important">
                        {{ selectedSbsObject?.models?.length }}
                      </div>
                      <v-icon small class="mx-2 flex-0-important"
                        >mdi-clipboard-text-multiple-outline
                      </v-icon>
                      {{ $t('system.model.multi') | capitalize }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div
                        v-for="model in selectedSbsObject?.models"
                        :key="model.id"
                        class="ant-glass-background ant-glass-background--plain d-flex align-center pa-2 ant-border-bottom sbs-detail-model-container"
                        @click="openForge(model)"
                      >
                        {{ model.name }}
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <rfi-dialog
                  :is-shown="showRfiCreate"
                  :project="project"
                  :sbs-object="selectedSbsObject"
                  @closeDialog="onRfiCreateClose"
                  @changeRfi="selectSbsObject(selectedSbsObject)"
                />
              </template>
              <div
                v-else
                class="d-flex align-center justify-center full-width full-height"
              >
                <ant-loading />
              </div>
            </div>
          </div>
        </div>
      </div>
    </panel-resizable>
    <div
      v-else
      class="full-height d-flex align-center z-100 relative"
      :style="{
        width: 0,
        position: 'relative',
        'z-index': 100,
      }"
    >
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-icon
            large
            class="menu-toggle-action"
            v-bind="attrs"
            style="pointer-events: all"
            v-on="on"
            @click="toggleSbsSidebar"
          >
            mdi-chevron-right
          </v-icon>
        </template>
        <span>{{ $t('system.sbsTree.toggleSidebar') }}</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AntLoading from '@/components/AntLoading';
import moment from 'moment';
import PanelResizable from '@/components/Project/PanelResizable';
import TaskV2 from '@/components/Tasks/TaskV2.vue';
import RfiDialog from '@/components/Tasks/RFI/RfiDialog.vue';
import { FORGE } from '@/modules/modules';

export default {
  name: 'SbsTree',
  components: {
    RfiDialog,
    TaskV2,
    PanelResizable,
    AntLoading,
  },
  data: () => {
    return {
      sbsSearch: null,
      open: [],
      active: [],

      tree: [],
      treeLoading: false,
      detailExpansions: [0],
      activeSbsCode: null,
      showRfiCreate: false,
      defautWidht: 400,
    };
  },
  computed: {
    ...mapGetters([
      'project',
      'selectedSbsObject',
      'sbsTree',
      'sbsTreeStatus',
      'sbsSearchRecords',
      'sbsRecords',
      'sbsSidebarToggle',
      'selectedSbsVersion',
      'sbsVersions',
      'selected3DSbsObject',
    ]),
    getTreeItems() {
      if (this.sbsSearchRecords.length > 0) {
        return this.sbsSearchRecords;
      }
      return this.sbsTree;
    },
    sortedVersions() {
      return [...this.sbsVersions].sort((a, b) =>
        moment(b.created_at).diff(moment(a.created_at))
      );
    },
    isForgeActive() {
      return this.project.modules.find((module) => module.route === FORGE)
        ?.accessible;
    },
  },
  watch: {
    sbsSearchRecords(value) {
      let record = value[0];
      while (record?.hasChildren) {
        this.open.push(record.code);
        record = record.children[0];
      }
    },
    sbsSidebarToggle(value) {
      if (value) {
        this.fetchSbsVersions();
        if (this.$route.query.sbscode) {
          this.sbsSearch = this.$route.query.sbscode;
          this.searchSbsTree();
          this.$store
            .dispatch('loadSbsRecords', { projectId: this.project.id })
            .then(() => {
              let object = this.sbsRecords.find(
                (item) => item.code === this.$route.query.sbscode
              );
              if (object) {
                this.selectSbsObject(object);
              }
            });
        } else {
          this.fetchSbsTree();
        }
        this.$store.commit('sbs_sidebar_width_success', this.defautWidht);
      }
    },
    selectedSbsVersion(value) {
      this.clearSbsSearch();
      this.fetchSbsTree();
    },
    selected3DSbsObject(value) {
      this.sbsSearch = value;
      this.$store.dispatch('searchSbsRecords', {
        projectId: this.project.id,
        searchValue: value,
      });
      this.$store
        .dispatch('loadSbsRecords', { projectId: this.project.id })
        .then(() => {
          let object = this.sbsRecords.find((item) => item.code === value);
          if (object) {
            this.selectSbsObject(object);
          }
        });
    },
  },
  mounted() {
    if (this.$route.query.sbscode) {
      this.$store.commit('open_sbs_sidebar');
    }
  },
  methods: {
    openForge(model) {
      this.$router.push({
        name: FORGE,
        params: { modelId: model.id },
        query: { u: new Date().valueOf().toString(36) },
      });
    },
    getSessionProgress(session) {
      let total = session.jobs.length;
      let completed = session.jobs.filter(
        (job) => job.status === 'completed'
      ).length;

      return Math.floor((completed / total) * 100);
    },
    openWorkflowSession(session) {
      this.$router.push({
        path: `/project/${this.project.slug}/workflows/${session.workflow}/sessions/${session.id}`,
      });
    },
    isVersionSelected(item) {
      return this.selectedSbsVersion
        ? item.id === this.selectedSbsVersion.id
        : false;
    },
    deselectSbsVersion() {
      this.$store.commit('select_sbs_version', null);
    },
    selectSbsVersion(version) {
      if (this.selectedSbsVersion === version) {
        this.deselectSbsVersion();
      } else {
        this.$store.commit('select_sbs_version', version);
      }
    },
    fetchSbsTree() {
      this.$store.dispatch('loadSbsTree', { projectId: this.project.id });
    },
    fetchSbsVersions() {
      this.$store.dispatch('loadSbsVersions', { projectId: this.project.id });
    },
    clearSbsSearch() {
      this.open = [];
      if (this.$route.query.sbscode) {
        this.$router.replace({ query: null });
      }
      this.$store.commit('sbs_search_clear');
    },
    fetchSbsChildren(sbs) {
      this.treeLoading = true;
      this.$store
        .dispatch('fetchSbsChildren', { projectId: this.project.id, sbs })
        .then((children) => {
          children
            .map((child) => {
              if (child.hasChildren) {
                child.children = [];
                return child;
              }
            })
            .sort((a, b) =>
              a.label > b.label ? 1 : b.label > a.label ? -1 : 0
            );
          sbs.children.push(...children);
          this.open.push(sbs.code);
          this.treeLoading = false;
        });
    },
    searchSbsTree() {
      if (this.sbsSearch) {
        if (this.sbsSearch.length > 2) {
          this.$store.dispatch('searchSbsTree', {
            searchValue: this.sbsSearch,
          });
        } else {
          this.$store.commit('showNotification', {
            content: 'Search value should be at least 2 characters long',
            color: 'error',
          });
        }
      }
    },
    toggleSbsSidebar() {
      this.$store.commit('toggle_sbs_sidebar');
    },
    closeSbsSidebar() {
      this.$store.commit('close_sbs_sidebar');
      this.deselectObjectTree();
    },
    selectSbsObject(item) {
      if (!item.workflow) {
        this.activeSbsCode = item.code;
        this.$store
          .dispatch('selectSBSObject', {
            sbsObject: item,
          })
          .catch(() => {
            this.activeSbsCode = null;
          });
      } else {
        this.$router.push({
          name: 'project-workflow-session',
          params: { workflowId: item.workflow, sessionId: item.id },
        });
      }
    },
    isSelectedObject(item) {
      if (this.selectedSbsObject !== undefined) {
        return (
          item.code === this.selectedSbsObject.code &&
          item.code === this.activeSbsCode
        );
      }
    },
    deselectObjectTree() {
      this.activeSbsCode = null;
      this.$store.dispatch('deselectSBSObject');
    },
    onStopResize({ width }) {
      this.$store.commit('sbs_sidebar_width_success', width);
      this.$store.commit('update_sbs_render');
    },
    onRfiCreateClose() {
      this.showRfiCreate = false;
    },
  },
};
</script>

<style scoped lang="scss">
.sbs-detail-container {
  transition: 200ms ease-out;
}
.menu-toggle-action {
  background-color: transparent;
  border-color: transparent;
  transform: translateX(-30%);
  &:hover {
    color: var(--v-primary-base);
    background-color: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(2px);
    border-radius: 5px;
    border-width: 1px;
    border-color: rgba(126, 126, 126, 0.2);
    border-style: solid;
    transform: translateX(5%);
  }
}
.sbs-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: auto;
}
.sbs-detail-session-container {
  transition: 200ms ease-out;
  cursor: pointer;

  &:hover {
    color: var(--v-primary-base);
    background: #f2f2f2;
  }
}
.sbs-detail-model-container {
  transition: 200ms ease-out;
  cursor: pointer;

  &:hover {
    color: var(--v-primary-base);
    background: #f2f2f2;
  }
}
</style>
